<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" :disabled="!store.accessRightsHash.VEHICLE_INSPECTION_EDIT" @click="operation.add.click">添加检测类别</en-button>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :options="[
        { label: '未启用', value: 'N' },
        { label: '已启用', value: 'Y' }
      ]"
      :method="table.get"
    ></tabs-maintain>

    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="VHISTCTYFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] }">
            <button-delete :method="table.operation.delete.click" :params="row">删除</button-delete>
            <button-delete :method="table.operation.enable.click" :params="row" v-if="row.enable?.code === 'Y'">禁用</button-delete>
            <button-delete :method="table.operation.enable.click" :params="row" v-if="row.enable?.code === 'N'">启用</button-delete>
          </template>

          <template #STATUS="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] }">
            <en-tag :type="row.enable?.code === 'N' ? 'info' : ''">{{ row.enable?.code === 'Y' ? '已启用' : '未启用' }}</en-tag>
          </template>

          <template #SERIAL_NO="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] }">
            <en-button type="primary" link @click="table.operation.serialNo.click(row)">{{ row.serialNo }}</en-button>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '检测类别'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="类别编号">
        <en-input :model-value="detail.form.data.serialNo" disabled placeholder=""></en-input>
      </en-form-item>
      <en-form-item label="检测类别名称" prop="name">
        <en-input v-model="detail.form.data.name" :disabled="detail.form.disabled"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax v-if="!detail.form.data.enable?.value" :method="detail.footer.confirm.click">确定</button-ajax>
      <button-ajax :method="detail.footer.enable.click">
        {{ detail.form.data.enable?.value ? '禁用' : '启用' }}
      </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es'

export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/inspection/category',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { enableCode: this.tabs.active }
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/vehicle/inspection/category/:categoryId',
            loading: true
          },
          enable: {
            action: 'PUT /enocloud/common/vehicle/inspection/category/enable',
            loading: true,
            convert(data) {
              data.enable.code = data.enable.code === 'Y' ? 'N' : 'Y'
              return data
            }
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            enable: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto']) {
                await this.table.enable({
                  payload: Object.assign({}, row, { enable: { code: row.enable?.value ? 'N' : 'Y' } })
                })
                return this.table.get()
              }
            },
            serialNo: {
              click(row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        },
        config: {
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          NAME: { header: { filter: { type: 'text', field: 'categoryName' } } }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/inspection/category/:categoryId',
                data: 'object',
                loading: true,
                init: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/vehicle/inspection/category',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/vehicle/inspection/category',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              enable: {
                action: 'PUT /enocloud/common/vehicle/inspection/category/enable',
                loading: true,
                params(params) {
                  params.payload = cloneDeep(this.detail.form.data)
                  params.payload.enable!.code = params.payload.enable?.code === 'Y' ? 'N' : 'Y'
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入检测类别名称' }]
            },
            computed: {
              disabled() {
                return this.detail.form.data.enable?.value || !this.store.accessRightsHash.VEHICLE_INSPECTION_EDIT
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            },
            enable: {
              async click() {
                await this.detail.form.enable()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
